import { VListItem } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.checkHasData)?_c('div',{staticClass:"flex items-center select-wrapper"},[_c(VSelect,{staticClass:"fit c-form-set-list__autocomplete-text-2xl",attrs:{"items":_vm.items,"text":"text","item-text":"text","item-value":"id","item-disabled":"itemDisabled","hide-details":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
var parent = ref.parent;
return [_c(VListItem,_vm._g(_vm._b({key:("form-set-select-" + (item.id)),staticClass:"dashboard-dropdown flex items-center",attrs:{"disabled":_vm.isDisabled(item.formType) && !_vm.isFreeTrial,"value":item.value}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"text-primary",class:{'text-grey-2 opacity-75': _vm.isDisabled(item.formType) && !_vm.isFreeTrial}},[_vm._v(_vm._s(item.text))]),(_vm.isDisabled(item.formType) && !_vm.isFreeTrial)?_c('fa-icon',{staticClass:"ml-2 text-grey-2 opacity-75",attrs:{"icon":"lock"}}):_vm._e(),(_vm.isDisabled(item.formType))?_c('div',{staticClass:"uppercase text-xs text-white bg-green py-1 px-2 rounded ml-2 font-bold"},[_vm._v("UPGRADE PLAN ")]):_vm._e()],1)]}}],null,false,1993453267),model:{value:(_vm.filter.formSetId),callback:function ($$v) {_vm.$set(_vm.filter, "formSetId", $$v)},expression:"filter.formSetId"}}),_c('date-picker',{ref:"datePicker",staticClass:"ml-4 mt-3 bg-navy-light rounded",attrs:{"prefixClass":'xmx',"range":"","clearable":false,"shortcuts":_vm.shortcuts,"placeholder":"Select date range"},on:{"change":_vm.dateRangeChanged},scopedSlots:_vm._u([{key:"icon-calendar",fn:function(){return [_c('fa-icon',{staticClass:"text-grey-2",attrs:{"icon":"calendar-alt"}})]},proxy:true},{key:"input",fn:function(){return [_c(VTextField,{attrs:{"filled":"","hide-details":"","dense":""},model:{value:(_vm.formattedRangeText),callback:function ($$v) {_vm.formattedRangeText=$$v},expression:"formattedRangeText"}})]},proxy:true}],null,false,3034996296),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }